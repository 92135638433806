import React, { Component } from "react";
import PropTypes from "prop-types";

import * as differ from "diff";
import { compact, isNil, startCase } from "lodash";

export default class EventDetailTable extends Component {
  static propTypes = {
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDiff: false,
    };
  }

  thumbnails = (arr) =>
    arr.map((img) => (
      <div
        className="product-thumbnail mr-2"
        data-toggle="popover"
        data-html="true"
        data-content={
          <img className="product-thumbnail--popover" src={`${img}`} />
        }
      >
        <img src={`https://images-na.ssl-images-amazon.com/images/I/${img}`} />
      </div>
    ));

  handleToggleDiff = (e) => {
    this.setState({
      showDiff: !this.state.showDiff,
    });
  };

  getDiff = (a, b) => {
    a = a || "";
    b = b || "";
    return differ.diffWords(a, b, ["ignoreCase"]).map((part) => {
      if (part.removed) {
        console.log(part);
      }
      const colorClass = part.added
        ? "bg-green-100"
        : part.removed
          ? "bg-red-100"
          : "";
      return <span className={colorClass}>{part.value}</span>;
    });
  };

  render() {
    let item = this.props.item;

    let head = (item) => {
      switch (item.change_type) {
        case "variation":
          return (
            <thead>
              <tr>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  State
                </th>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Variations
                </th>
              </tr>
            </thead>
          );
        case "image":
          return (
            <thead>
              <tr>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  State
                </th>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Images
                </th>
              </tr>
            </thead>
          );
        case "indexation":
          return (
            <thead>
              <tr>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Previous
                </th>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Current
                </th>
              </tr>
            </thead>
          );
        case "content":
          return (
            <thead>
              <tr>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Element
                </th>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Previous
                </th>
                <th className="border-b font-medium p-4 pl-8 py-2 text-gray-400 text-left">
                  Current
                </th>
              </tr>
            </thead>
          );
      }
    };

    let body = (item) => {
      switch (item.change_type) {
        case "variation":
          return (
            <tbody className="bg-white">
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Current
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  {isNil(item.metadata["current"]) ? null : item.metadata["current"].join(", ")}
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Previous
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  {isNil(item.metadata["previous"]) ? null : item.metadata["previous"].join(", ")}
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Added
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-green-500">
                  {isNil(item.metadata["added"]) ? null : item.metadata["added"].join(", ")}
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Removed
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-red-500">
                  {isNil(item.metadata["removed"]) ? null : item.metadata["removed"].join(", ")}
                </td>
              </tr>
            </tbody>
          );
        case "image":
          return (
            <tbody>
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Current
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  <span className="flex">
                    {this.thumbnails(item.metadata["current"])}
                  </span>
                  <span className="items-center bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  {`${item.metadata["added"].length} images added.`}
                  </span> 

                  <span className="items-center bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  {`${item.metadata["removed"].length} images removed.`}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  Previous
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  <span className="flex">
                    {this.thumbnails(item.metadata["previous"])}
                  </span>
                </td>
              </tr>
            </tbody>
          );
        case "indexation":
          return (
            <tbody>
              <tr className="bg-white">
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  {!item.indexed ? "Indexed" : "Not Indexed"}
                </td>
                <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                  {item.indexed ? "Indexed" : "Not Indexed"}
                </td>
              </tr>
            </tbody>
          );
        case "content":
          let updated_items = compact([
            item.title_updated ? "title" : null,
            item.description_updated ? "description" : null,
            item.bullet_1_updated ? "bullet_1" : null,
            item.bullet_2_updated ? "bullet_2" : null,
            item.bullet_3_updated ? "bullet_3" : null,
            item.bullet_4_updated ? "bullet_4" : null,
            item.bullet_5_updated ? "bullet_5" : null,
            item.brand_byline_updated ? "brand_byline" : null,
          ]);

          return (
            <tbody>
              {updated_items.map((element) => (
                <tr className="bg-white">
                  <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                    {element == "brand_byline"
                      ? "Brand Name"
                      : startCase(element)}
                  </td>
                  <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                    {item.metadata["previous"][element]}
                  </td>
                  <td className="border-b border-gray-200 p-4 pl-8 text-gray-500">
                    {this.state.showDiff
                      ? this.getDiff(
                        item.metadata["previous"][element],
                        item.metadata["current"][element]
                      )
                      : item.metadata["current"][element]}
                  </td>
                </tr>
              ))}
            </tbody>
          );
      }
    };

    if (isNil(item.metadata) && item.change_type !== "indexation") {
      return (
        <span className="text-red-500">
          Intel wasn't able to capture the data for this item.
        </span>
      );
    } else {
      return (
        <div>
          <div className="border not-prose relative bg-gray-50 rounded-xl overflow-hidden/25">
            <div
              style={{ backgroundPosition: "10px 10px" }}
              className="absolute inset-0 bg-grid-gray-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))]"
            ></div>
            <div className="relative rounded-xl overflow-auto">
              <div className="shadow-sm overflow-hidden my-2">
                <table className="border-collapse table-auto w-full text-sm">
                  {head(item)}
                  {body(item)}
                </table>
              </div>
              {["content"].includes(item.change_type) && (
                <div
                  className="flex items-center m-2"
                  role="navigation"
                  aria-label="pager"
                >
                  <button
                    onClick={() => this.handleToggleDiff()}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-2 py-1 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-2 sm:w-auto sm:text-sm ml-4"
                  >
                    Compare Diff
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
