import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { Doughnut } from "react-chartjs-2";

export default class DonutChart extends Component {
  render() {
    const { data, height, options } = this.props;

    console.log(this.props);
    return (
      <div>
        <Doughnut data={data} height={height} options={options} />
      </div>
    );
  }
}

DonutChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  }).isRequired,
  height: PropTypes.string,
  options: PropTypes.object.isRequired,
};

DonutChart.defaultProps = {
  data: {
    datasets: [],
    labels: [],
  },
  height: "auto",
  options: {},
};
