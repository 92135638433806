import React, { Component } from "react";
import PropTypes from "prop-types";
import EventFilterCard from "./EventFilterCard";

class EventFilterCards extends Component {
  static propTypes = {
    applyFilters: PropTypes.func,
    active: PropTypes.bool,
    indexationCount: PropTypes.number,
    contentCount: PropTypes.number,
    imageCount: PropTypes.number,
    changeTypeFilter: PropTypes.string,
    variationCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
  }

  render() {
    let {
      indexationCount,
      imageCount,
      changeTypeFilter,
      applyFilters,
      contentCount,
      variationCount,
    } = this.props;

    return (
      <div className="mb-4 grid gap-1 sm:grid-cols-2 lg:grid-cols-4">
        <EventFilterCard
          applyFilters={applyFilters}
          color="danger"
          count={indexationCount}
          heading="Indexation"
          icon="search"
          active={changeTypeFilter == "indexation"}
        />
        <EventFilterCard
          applyFilters={applyFilters}
          color="primary"
          count={contentCount}
          heading="Content"
          active={changeTypeFilter == "content"}
        />
        <EventFilterCard
          applyFilters={applyFilters}
          color="success"
          count={variationCount}
          heading="Variation"
          active={changeTypeFilter == "variation"}
        />
        <EventFilterCard
          applyFilters={applyFilters}
          color="warning"
          count={imageCount}
          heading="Image"
          active={changeTypeFilter == "image"}
        />
      </div>
    );
  }
}

export default EventFilterCards;
