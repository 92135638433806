import React, { Component } from "react";
import PropTypes from "prop-types";

import moment from "moment";
import queryString from "query-string";
import { localPostJSON, localGet } from "../fetch";
import {
  debounce,
  delay,
  orderBy,
  isEmpty,
  isNil,
  some,
  startCase,
  without,
} from "lodash";
import BarChart from "./BarChart";
import EventBulkActionForm from "./EventBulkActionForm";
import EventForm from "./EventForm";
import EventDetailTable from "./EventDetailTable";
import EventFilterCards from "./EventFilterCards";
import EventCategoryButtonGroup from "./EventCategoryButtonGroup";
import FilterTag from "./FilterTag";
import EventPagination from "./EventPagination";

class EventsManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activityCount: this.props.activityCount,
      allEventsOfChangeTypeSelected: false,
      categorizedCount: this.props.categorizedCount,
      categoryFilter: "any",
      changeTypeFilter: queryString.parse(location.search).change_type || "all",
      tagFilter: "any",
      chartData: this.props.chartData,
      endDateFilter: "",
      startDateFilter: "",
      chartLabels: this.props.chartLabels,
      events: null,
      clumpedEvents: null,
      isLoading: true,
      message: null,
      selectedCount: 0,
      searchFilter: "",
      selectedQueue: queryString.parse(location.search).queue || "to_review",
      showModal: false,
      uncategorizedCount: this.props.uncategorizedCount,
      indexationCount: this.props.indexationCount,
      contentCount: this.props.contentCount,
      variationCount: this.props.variationCount,
      imageCount: this.props.imageCount,
      bulkDropDownOpened: false,
      page: 1,
      scrollPosition: 0,
    };

    this.fetchEvents();
  }

  eventsWithAttributes = (events) =>
    JSON.parse(events).map((item) => {
      item.is_expanded = false;
      item.is_loading = false;
      item.is_selected = false;
      item.show = true;
      return item;
    });

  allEventsOfChangeTypeSelected = () => {
    let newEvents = this.state.events;
    if (!newEvents) return;

    let eventsOfSameChangeType = newEvents.filter(
      (e) => e.change_type == this.state.changeType
    );

    return (
      this.state.changeType &&
      Object.keys(eventsOfSameChangeType).every(
        (item) => eventsOfSameChangeType[item]["is_selected"]
      )
    );
  };

  hideMessage = () => {
    this.setState({
      message: null,
    });
  };

  message = () => {
    const { message } = this.state;
    if (!message) return null;

    return (
      <React.Fragment>
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-2 rounded relative"
          role="alert"
        >
          <span className="font-bold">{message}</span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => this.hideMessage()}
          >
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      </React.Fragment>
    );
  };

  handleBulkActionClick = () => {
    this.setState({
      bulkDropDownOpened: !this.state.bulkDropDownOpened,
    });
  };

  handleCheckClick = (e, item) => {
    let selectedId = item.id;
    let newEvents = this.state.events;

    newEvents.forEach((o) => {
      if (o.id == selectedId) {
        o.is_selected = !o.is_selected;
      }
    });

    if (item.change_type == "variation") {
      this.fetchRelatedVariations(item);
    }

    let newSelectedCount = this.selectedCount(newEvents);

    this.setState({
      events: newEvents,
      selectedCount: newSelectedCount,
      changeType: item.change_type,
    });
  };

  handleRowClick = (e, item) => {
    let selectedId = item.id;
    let newEvents = this.state.events;

    newEvents.forEach((o) => {
      if (o.id == selectedId) {
        o.is_expanded = !o.is_expanded;
      }
    });

    if (item.change_type == "variation") {
      this.fetchRelatedVariations(item);
    }

    this.setState({
      events: newEvents,
    });
  };

  fetchRelatedVariations = (item) => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();
    const url = `/events/related_variations/${item.id}.json`;

    localGet(url)
      .then((res) => {
        newEvents.map((e) => {
          if (item.id == e.id) {
            e.related = res.related;
          }
        });
        this.setState({
          events: newEvents,
        });
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  };


  selectAll = () => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();
    let { changeType, selectedQueue } = this.state;
    let allEventsOfChangeTypeSelected = this.allEventsOfChangeTypeSelected();

    newEvents.map((e) => {
      if (e.change_type == changeType) {
        e.is_selected = !allEventsOfChangeTypeSelected;
      } else if (selectedQueue == "categorized") {
        e.is_selected = !e.is_selected;
      }
    });

    let newSelectedCount = this.selectedCount(newEvents);

    this.setState({
      events: newEvents,
      selectedCount: newSelectedCount,
      allEventsOfChangeTypeSelected: allEventsOfChangeTypeSelected,
    });
  };

  handleChangeTypeFilterEvent = (e) => {
    this.setState(
      {
        changeTypeFilter: e.target.value,
        isLoading: true,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleCategoryFilterEvent = (e) => {
    this.setState(
      {
        categoryFilter: e.target.value,
        isLoading: true,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleTagFilterEvent = (e) => {
    this.setState(
      {
        tagFilter: e.target.value,
        isLoading: true,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleSearchFilterEvent = (e) => {
    this.setState(
      {
        searchFilter: e.target.value,
        isLoading: true,
      },
      debounce(() => {
        this.fetchEvents();
      }, 1500)
    );
  };

  handleDateFilterUpdate = (e) => {
    let field =
      e.target.getAttribute("name") == "start-date"
        ? "startDateFilter"
        : "endDateFilter";

    this.setState(
      {
        [field]: e.target.value,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handleEventFilterCardClick = (e) => {
    this.setState(
      {
        changeTypeFilter: e,
        isLoading: true,
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  handlePaginationClick = (e) => {
    this.setState(
      {
        page: e,
        isLoading: true,
        events: "",
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  selectedCount = (newEvents) => newEvents.filter((o) => o.is_selected).length;

  clearFilters = () => {
    this.setState(
      {
        changeTypeFilter: "all",
        categoryFilter: "any",
        tagFilter: "any",
        searchFilter: "",
        startDateFilter: "",
        endDateFilter: "",
      },
      () => {
        this.fetchEvents();
      }
    );
  };

  fetchEvents = () => {
    let brandId = this.props.brandId;
    const url = `/brands/${brandId}.json?queue=${this.state.selectedQueue}&start_date=${this.state.startDateFilter}&end_date=${this.state.endDateFilter}&is_chart_view=${this.props.isChartView}&change_type=${this.state.changeTypeFilter}&category=${this.state.categoryFilter}&search=${this.state.searchFilter}&tag=${this.state.tagFilter}&page=${this.state.page}`;

    localGet(url)
      .then((res) => {
        this.setState({
          events: res.events,
          clumpedEvents: res.events,
          categorizedCount: res.categorized_count,
          uncategorizedCount: res.uncategorized_count,
          activityCount: res.activity_count,
          selectedCount: 0,
          isLoading: false,
          chartData: res.chart_data,
          chartLabels: res.chart_labels,
          changeTypeFilter: this.state.changeTypeFilter,
          categoryFilter: this.state.categoryFilter,
          searchFilter: this.state.searchFilter,
          tagFilter: this.state.tagFilter,
          startDateFilter: this.state.startDateFilter,
          endDateFilter: this.state.endDateFilter,
          contentCount: res.content_count,
          indexationCount: res.indexation_count,
          imageCount: res.image_count,
          variationCount: res.variation_count,
        });
        window.scrollTo(0, this.state.scrollPosition);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  };

  convertJSONToCSV = (json) => {
    const items = json;
    const replacer = (key, val) => (val === null ? "" : val);
    const header = Object.keys(items[0]);
    let csv = items.map((row) => 
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(","));
      csv.unshift(header.join(","));
      return csv.join("\n");
  }

  // export events
  exportEvents = async () => {
    // find events
    await this.fetchEvents();
    const csv = this.convertJSONToCSV(this.state.events);
  
    // Create a Blob object to create a downloadable file
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    
    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `page-${this.state.page}_events.csv`);
    document.body.appendChild(a);
    a.click();
    
    // Cleanup
    window.URL.revokeObjectURL(url);
  }


  setScrollPosition = () => {
    this.setState({ scrollPosition: window.pageYOffset });
  };

  setToLoading = (eventIds) => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();
    let selectedEvents = eventIds || this.selectedEvents();

    this.setScrollPosition();

    newEvents.map((event) => {
      if (selectedEvents.includes(event.id)) {
        event.is_loading = true;
      }
    });

    this.setState({ events: newEvents, isLoading: false });
  };

  updateEvents = (eventIds, columns) =>
    new Promise((resolve, reject) => {
      this.setToLoading(eventIds);

      var stateCopy = Object.assign({}, this.state);
      let newEvents = stateCopy.events.slice();
      let eventAndRelated = newEvents.filter((e) => eventIds.includes(e.id));
      let eventAndRelatedIds = without(
        eventAndRelated.map((e) => {
          if (e.related) {
            return e.related.map((r) => r.event_id);
          }
        }),
        undefined
      );
      let noRelatedEvents = isEmpty(eventAndRelatedIds);

      this.setToLoading(eventAndRelatedIds);

      columns.event_ids = noRelatedEvents ? eventIds : eventAndRelatedIds;
      columns.user_id = this.props.currentUser.id;

      if (
        ["optimized", "new_variation", "added_variant", "in_stock"].includes(
          columns.category
        )
      ) {
        columns.activity_type = "optimization";
      }

      delay(
        () => {
          let brandId = this.props.brandId;
          const url = `/brands/${brandId}/events/bulk_update`;
          localPostJSON(url, columns)
            .then((res) => {
              this.setState(
                {
                  message: res.message,
                },
                () => {
                  this.fetchEvents();
                  this.closeModal();
                }
              );
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                message:
                  "Ugh oh, looks like there was an error. Try again please.",
              });
              reject(err);
            });
        },
        125,
        eventIds,
        newEvents
      );
    });

  ignoreEvents = (eventIds) => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();
    this.setToLoading(eventIds);

    delay(
      () => {
        let brandId = this.props.brandId;
        const url = `/brands/${brandId}/events/destroy`;
        localPostJSON(url, { event_ids: eventIds })
          .then((res) => {
            this.fetchEvents();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      125,
      eventIds,
      newEvents
    );
  };

  selectedEvents = () => {
    var stateCopy = Object.assign({}, this.state);
    if (!stateCopy.events) return;

    return stateCopy.events.filter((event) => event.is_selected);
  };

  ignoreEvents = (eventIds) => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();
    this.setToLoading(eventIds);

    delay(
      () => {
        let brandId = this.props.brandId;
        const url = `/brands/${brandId}/events/ignore_events`;
        localPostJSON(url, { event_ids: eventIds })
          .then((res) => {
            this.fetchEvents();
          })
          .catch((err) => {
            console.log(err);
          });
      },
      1000,
      eventIds,
      newEvents
    );
  };

  showModal = () => {
    this.setState({
      bulkDropDownOpened: !this.state.bulkDropDownOpened,
      showModal: true,
    });
  };

  handleMultiIgnore = debounce(() => {
    this.setState({
      bulkDropDownOpened: false,
    });

    let selectedEvents = this.selectedEvents().map((e) => e.id);
    this.ignoreEvents(selectedEvents);
  }, 500);

  handleMultiUncategorize = debounce(() => {
    this.setState({
      bulkDropDownOpened: false,
    });

    let selectedEvents = this.selectedEvents().map((e) => e.id);
    this.updateEvents(selectedEvents, {
      category: "uncategorized",
      activity_type: "unknown",
    });
  }, 500);

  handleCloseClick = (e) => {
    var stateCopy = Object.assign({}, this.state);
    let newEvents = stateCopy.events.slice();

    newEvents.map((event) => {
      if (e.id == event.id) {
        event.is_expanded = false;
      }
    });

    this.setState({ events: newEvents });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  sortCarets = (context) => (
    <div className="flex flex-col">
      <span
        className="fill-blue-700 cursor-pointer"
        onClick={() => this.sortEventsBy(context, "desc")}
      >
        <i className="fe fe-arrow-up"></i>
      </span>
      <span
        className="hover:fill-blue-700 cursor-pointer"
        onClick={() => this.sortEventsBy(context, "asc")}
      >
        <i className="fe fe-arrow-down"></i>
      </span>
    </div>
  );

  sortEventsBy = (context, order) => {
    var stateCopy = Object.assign({}, this.state);
    let events = stateCopy.events.slice();
    let newEvents = orderBy(events, [context], [order]);

    this.setState({ events: newEvents });
  };

  render() {
    const {
      categoryFilter,
      changeTypeFilter,
      tagFilter,
      chartData,
      events,
      selectedCount,
      changeType,
      selectedQueue,
      searchFilter,
      isLoading,
      startDateFilter,
      endDateFilter,
    } = this.state;

    const { brandId, chartOptions, chartView } = this.props;

    const changeTypeFilterOptions = this.props.changeTypeFilterOptions.map(
      (o) => <option value={o.value}>{o.label}</option>
    );

    const categoryFilterOptions = this.props.categoryFilterOptions.map((o) => (
      <option value={o.value}>{o.label}</option>
    ));

    const tagFilterOptions = this.props.tagFilterOptions.map((o) => (
      <option value={o.value}>{o.label}</option>
    ));

    let hasEvents =
      !isNil(events) && events.length > 0 && some(events, (e) => e.show);

    let isForReviewQueue = isNil(selectedQueue) || selectedQueue == "to_review";
    let isCategorizedQueue = selectedQueue == "categorized";
    let isActivityQueue = selectedQueue == "activities";

    let changeTypeFilterTag =
      changeTypeFilter == "all" ? null : (
        <FilterTag
          onClick={this.clearFilters}
          value={
            this.props.changeTypeFilterOptions.filter(
              (o) => o.value == changeTypeFilter
            )[0].label
          }
        />
      );

    let categoryFilterTag =
      categoryFilter == "any" ? null : (
        <FilterTag
          onClick={this.clearFilters}
          value={
            this.props.categoryFilterOptions.filter(
              (o) => o.value == categoryFilter
            )[0].label
          }
        />
      );

    let searchFilterTag = searchFilter ? (
      <FilterTag onClick={this.clearFilters} value={this.state.searchFilter} />
    ) : null;

    let tagFilterTag =
      tagFilter == "any" ? null : (
        <FilterTag
          onClick={this.clearFilters}
          value={
            this.props.tagFilterOptions.filter((o) => o.value == tagFilter)[0]
              .label
          }
        />
      );

    let dateFilterTag =
      startDateFilter && endDateFilter ? (
        <FilterTag
          onClick={this.clearFilters}
          value={`${this.state.startDateFilter} - ${this.state.endDateFilter}`}
        />
      ) : null;

    let selectAll = (
      <input
        type="checkbox"
        className="checkbox-lg"
        checked={this.allEventsOfChangeTypeSelected()}
        disabled={selectedCount == 0 && isForReviewQueue}
        onClick={this.selectAll}
      />
    );

    let activityDateFilters = (
      <div className="d-flex">
        <input
          type="date"
          id="start"
          name="start-date"
          autoComplete="false"
          value={"" || this.state.startDateFilter}
          onChange={(e) => this.handleDateFilterUpdate(e)}
          min="2019-01-01"
          className={this.props.selectClass}
        />
        <input
          type="date"
          id="end"
          name="end-date"
          autoComplete="false"
          value={"" || this.state.endDateFilter}
          onChange={(e) => this.handleDateFilterUpdate(e)}
          min="2019-01-01"
          className={this.props.selectClass}
        />
        <button
          className={this.props.buttonClass}
          type="button"
          onClick={() => this.fetchEvents()}
        >
          Apply
        </button>
      </div>
    );

    let filter = (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="pb-3">
            <div className="grid gap-1 sm:grid-cols-6 lg:grid-cols-6">
              <div className="w-100">
                <label htmlFor="ASIN or Product" className={this.props.labelClass}>ASIN or Product</label>
                <input
                  type="text"
                  className={this.props.inputClass}
                  value={this.state.searchFilter || ""}
                  placeholder="Search by product or ASIN..."
                  onChange={this.handleSearchFilterEvent}
                />
              </div>
              <div className="w-100">
                <label htmlFor="Tag" className={this.props.labelClass}>Tag</label>
                <select
                  className={this.props.selectClass}
                  onChange={this.handleTagFilterEvent}
                >
                  {tagFilterOptions}
                </select>
              </div>
              <div className="w-100">
                <label htmlFor="Start Date" className={this.props.labelClass}>Start Date</label>
                <input
                  type="date"
                  className={this.props.inputClass}
                  autoComplete="false"
                  name="start-date"
                  value={"" || this.state.startDateFilter}
                  onChange={(e) => this.handleDateFilterUpdate(e)}
                />
              </div>
              <div className="w-100">
                <label htmlFor="End Date" className={this.props.labelClass}>End Date</label>
                <input
                  type="date"
                  className={this.props.inputClass}
                  autoComplete="false"
                  name="end-date"
                  value={"" || this.state.endDateFilter}
                  onChange={(e) => this.handleDateFilterUpdate(e)}
                />
              </div>
              {!isForReviewQueue ? (
                <div className="w-100">
                  <label htmlFor="Category" className={this.props.labelClass}>Category</label>
                  <select
                    className={this.props.selectClass}
                    onChange={this.handleCategoryFilterEvent}
                  >
                    {categoryFilterOptions}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
        </form>
        <div className="w-100 flex">
          {changeTypeFilterTag}
          {categoryFilterTag}
          {searchFilterTag}
          {tagFilterTag}
          {dateFilterTag}
        </div>
      </div>
    );

    let emptyState = (
      <div className="text-center py-6">
        <h3>No Changes Found</h3>
        <p className="muted">
          There are no changes or activities matching this criteria.
          <br /> If this seems odd, please{" "}
          <a
            className="cursor-pointer text-primary"
            onClick={() => this.clearFilters()}
          >
            clear your filters
          </a>
          , otherwise you're all set.
        </p>
      </div>
    );

    let loadingState = (
      <div className="text-center py-6">
        <h3>
          <i className="fa fa-spinner fa-pulse fa-fw"></i> Loading...
        </h3>
        <p className="muted">One second. Loading some data.</p>
      </div>
    );

    let whatChanged = (item) => {
      switch (item.change_type) {
        case "content":
          return (
            <span className="grid grid-cols-2 gap-1">
              {item.title_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Title
                </span>
              ) : (
                ""
              )}
              {item.description_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Description
                </span>
              ) : (
                ""
              )}
              {item.bullet_1_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Bullet 1
                </span>
              ) : (
                ""
              )}
              {item.bullet_2_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Bullet 2
                </span>
              ) : (
                ""
              )}
              {item.bullet_3_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Bullet 3
                </span>
              ) : (
                ""
              )}
              {item.bullet_4_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Bullet 4
                </span>
              ) : (
                ""
              )}
              {item.bullet_5_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Bullet 5
                </span>
              ) : (
                ""
              )}
              {item.brand_byline_updated ? (
                <span
                  className={`items-center bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
                >
                  Brand Name
                </span>
              ) : (
                ""
              )}
            </span>
          );
        case "image":
          if (!item.images_added && item.images_removed) {
            return (
              <span
                className={`items-center bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
              >
                {item.metadata.removed.length} Images Removed
              </span>
            );
          } else if (item.images_added && !item.images_removed) {
            return (
              <span
                className={`items-center bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
              >
                {item.metadata.added.length} Images Added
              </span>
            );
          } else if (item.images_added && item.images_removed && (item.metadata.added.length !== item.metadata.removed.length)) {
            return (
              <span
                className={`items-center bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
              >
                Image Updates
              </span>
            )
          } 
          else {
            return (
              <span
                className={`items-center bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
              >
                Image
              </span>
            );
          }
        case "indexation":
          return !item.indexed ? (
            <span
              className={`items-center bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
            >
              {`Indexed -> Not indexed`}
            </span>
          ) : (
            <span
              className={`items-center bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
            >
              {`Not indexed -> Indexed`}
            </span>
          );
        case "variation":
          return (
            <span
              className={`items-center bg-indigo-100 text-indigo-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}
            >
              <i className="fa fa-sitemap" /> Variation
            </span>
          );
        default:
          return <i className="fa fa-bug" />;
      }
    };

    let items = isLoading
      ? loadingState
      : !hasEvents
      ? emptyState
      : events.map((item, i) => {
          if (item.change_type === "image" && item.metadata.added.length === item.metadata.removed.length) {
            return
          }
          let canSelectItem =
            changeType == item.change_type || isCategorizedQueue;
          let isDisabled =
            !canSelectItem && selectedCount !== 0 && !item.is_selected;
          let productTitle = (
            <strong>
              {item.product.title && item.product.title.length > 70
                ? `${item.product.title.substring(0, 70)}...`
                : item.product.title}
            </strong>
          );

          let category = null;
          if (item.category == "uncategorized") {
            category = (
              <span className={`badge badge-soft-danger`}>
                {startCase(item.category)}
              </span>
            );
          } else if (item.is_loading) {
            category = (
              <span className={`badge badge-soft-gray`}>Loading…</span>
            );
          } else {
            category = (
              <span className={`badge badge-secondary`}>
                {startCase(item.category)}
              </span>
            );
          }

          let detailRow = (
            <div className="grid grid-cols-12 gap-4 p-4 b">
              <div className="col-span-8">
                <EventDetailTable item={item} />
              </div>
              <div className="col-span-4">
                <div className="bg-white p-4 border rounded">
                  <a
                    className={"btn btn-outline-secondary btn-sm float-right cursor-pointer hover:text-blue text-gray-500"}
                    onClick={(e) => this.handleCloseClick(item)}
                  >
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                  </a>

                  <EventForm
                    activityTypes={this.props.activityTypes}
                    brandId={brandId}
                    categories={this.props.categories}
                    ignoreEvents={() => this.ignoreEvents([item.id])}
                    event={item}
                    fetchEvents={() => this.fetchEvents()}
                    isLoading={this.state.isLoading}
                    selectedQueue={this.state.selectedQueue}
                    setToLoading={() => this.setToLoading()}
                    isForReviewQueue={isForReviewQueue}
                    updateEvents={(eventIds, columns) =>
                      this.updateEvents(eventIds, columns)
                    }
                  />
                </div>
              </div>
            </div>
          );

          return (
            <div
              className={
                "event-table-row" + (item.is_loading ? " opacity-25" : "")
              }
            >
              <div
                onClick={
                  isDisabled ? null : (e) => this.handleRowClick(e, item)
                }
                className={
                  "event-table--data" +
                  (item.is_selected ? " event-selected" : "") +
                  (!item.show ? " d-none" : "") +
                  (item.is_loading ? " opacity-25 event-disabled" : "") +
                  (isDisabled ? " event-disabled" : "")
                }
                key={`issue-${item.id}`}
              >
                <div className="event-table--data-cell event-col--selectbox">
                  <input
                    type="checkbox"
                    value={item.id}
                    disabled={isDisabled}
                    checked={item.is_selected}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleCheckClick(e, item);
                    }}
                    className="checkbox-lg"
                  />
                </div>
                <div className="event-table--data-cell event-col--asin">
                  <a
                    href={`https://amazon.com/dp/${item.product.asin}`}
                    className="text-muted text-reset text-primary"
                    target="_blank"
                  >
                    {item.product.asin}{" "}
                    <i className="fa fa-external-link fa-xs" />
                  </a>
                </div>
                <div className="event-table--data-cell event-col--parent-asin">
                  {item.product.parent_asin ? (
                    <a
                      href={`https://amazon.com/dp/${item.product.asin}`}
                      className="text-muted text-reset text-primary"
                      target="_blank"
                    >
                      {item.product.parent_asin}{" "}
                      <i className="fa fa-external-link fa-xs" />
                    </a>
                  ) : null}
                </div>
                <div className="event-table--data-cell event-col--product">
                  <span className="text-muted text-reset text-primary">
                    {productTitle}
                    <a
                      href={`/brands/${brandId}/products/${item.product.asin}`}
                      className="text-muted text-reset text-primary"
                    >
                      View
                    </a>
                  </span>
                </div>
                <div className="event-table--data-cell event-col--change-type">
                  {whatChanged(item)}
                </div>
                <div className="event-table--data-cell event-col--created-at">
                  {moment(item.created_at).format("MM/DD/YYYY")}
                </div>
                <div className="event-table--data-cell event-col--tags">
                  <span className="grid grid-cols-2 gap-1">
                    {item.tags ? item.tags.map((tag) => (
                      <span className="items-center bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{tag}</span>
                    )) : null}
                  </span>
                </div>
                <div className="event-table--data-cell event-col--updated-by">
                  {item.user
                    ? item.user.first_name + " " + item.user.last_name
                    : null}
                </div>
                <div className="event-table--data-cell event-col--category">
                  {category}
                </div>
                <div className="event-table--data-cell event-col--activity-type">
                  {startCase(item.activity_type)}
                </div>
                <div className="event-table--data-cell event-col--notes">
                  {item.notes}
                </div>
              </div>
              {item.is_expanded && item.show ? detailRow : null}
            </div>
          );
        });

    return (
      <div className="container-fluid">
        <div className="mb-4 w-full flex flex-wrap items-center justify-between">
          <h1 className="text-4xl font-bold mb-4">
            {isForReviewQueue
              ? `${this.props.brandName}: Recent Changes`
              : isCategorizedQueue
                ? `${this.props.brandName}: Pending Changes`
                : `${this.props.brandName}: Logged Activities`}
          </h1>
          <div className="mb-4">
            <div>
              <select
                className={this.props.selectClass}
                value={this.state.changeTypeFilter}
                selected={this.state.changeTypeFilter}
                onChange={this.handleChangeTypeFilterEvent}
              >
                {changeTypeFilterOptions}
              </select>
            </div>
          </div>
          <button className="py-2 px-4 text-sm font-medium border border-gray-200 hover:bg-gray-100 hover:text-blue-700 bg-white rounded-md" onClick={() => {
    document.getElementById('EventsManager').style.display = 'none';
    document.getElementById('EventsManager2').style.display = 'block';
}}>View Stacked Variations</button>

        </div>
        <EventBulkActionForm
          activityTypes={this.props.activityTypes}
          categories={this.props.categories}
          closeModal={() => this.closeModal()}
          ignoreEvents={(selectedEvents, event) =>
            this.ignoreEvents(selectedEvents, event)
          }
          isForReviewQueue={isForReviewQueue}
          isLoading={this.state.isLoading}
          fetchEvents={() => this.fetchEvents()}
          changeType={this.state.changeType}
          selectedEvents={this.selectedEvents()}
          selectedQueue={selectedQueue}
          setToLoading={() => this.setToLoading()}
          showModal={this.state.showModal}
          updateEvents={(eventIds, columns) =>
            this.updateEvents(eventIds, columns)
          }
        />
        <div className="row">
          <div className="col-md-12">{this.message()}</div>
        </div>
        {isActivityQueue ? null : (
          <EventFilterCards
            applyFilters={this.handleEventFilterCardClick}
            indexationCount={this.state.indexationCount}
            imageCount={this.state.imageCount}
            contentCount={this.state.contentCount}
            variationCount={this.state.variationCount}
            changeTypeFilter={this.state.changeTypeFilter}
          />
        )}
        <div className="mb-4 w-full flex flex-wrap items-center justify-between">
          <EventCategoryButtonGroup
            brandId={this.props.brandId}
            uncategorizedCount={this.state.uncategorizedCount}
            categorizedCount={this.state.categorizedCount}
            activityCount={this.state.activityCount}
            isForReviewQueue={isForReviewQueue}
            isCategorizedQueue={isCategorizedQueue}
            isActivityQueue={isActivityQueue}
          />
          <div className="cursor-pointer w-100">
            <div className="relative flex flex-row items-center	 w-100">
              <div className="mr-4">           
                  <a
                  className="py-2 px-4 text-sm font-medium border border-gray-200 hover:bg-gray-100 hover:text-blue-700 bg-white rounded-md"
                  onClick={() => this.exportEvents()}
                >
                  Export CSV
                </a>
              </div>

              <div className="w-100">
                <button
                  type="button"
                  className={
                    "shadow px-3 py-4 inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:shadow-outline"
                  }
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                  onClick={() => this.handleBulkActionClick()}
                >
                  Bulk Actions
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={
                  "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none " +
                  (this.state.bulkDropDownOpened ? "block" : "hidden")
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-1" role="none">
                  {isForReviewQueue ? (
                    <a
                      className="text-gray-700 block px-4 py-2 text-sm"
                      onClick={() => this.showModal()}
                    >
                      Categorize selected
                    </a>
                  ) : (
                    <a
                      className="text-gray-700 block px-4 py-2 text-sm"
                      onClick={() => this.handleMultiUncategorize()}
                    >
                      Uncategorize selected
                    </a>
                  )}
                  {isCategorizedQueue ? (
                    <a
                      className="text-gray-700 block px-4 py-2 text-sm"
                      onClick={() => this.showModal()}
                    >
                      Log Activities for selected
                    </a>
                  ) : null}
                  <a
                    className="text-gray-700 block px-4 py-2 text-sm"
                    onClick={() => this.handleMultiIgnore()}
                  >
                    Ignore selected
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex">
            {isActivityQueue ? (
              null
              // <div
              //   className="btn-group"
              //   role="group"
              //   aria-label="Basic example"
              // >
              //   <a
              //     href={`/brands/${this.props.brandId}/?queue=activities&is_chart_view=0`}
              //     className={
              //       "btn btn-secondary" + (!chartView ? " active" : "")
              //     }
              //   >
              //     <i className="fa fa-list" /> List
              //   </a>
              //   <a
              //     href={`/brands/${this.props.brandId}/?queue=activities&is_chart_view=1`}
              //     className={"btn btn-secondary" + (chartView ? " active" : "")}
              //   >
              //     <i className="fa fa-bar-chart" /> Chart
              //   </a>
              // </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card event-container mb-3 p-0">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="select and deselect"
                  />
                  {chartView ? activityDateFilters : null}
                  {chartView ? null : filter}
                </div>
              </div>
              {chartView ? (
                <div className="p-4">
                  <BarChart
                    data={chartData}
                    height={100}
                    options={chartOptions}
                  />
                </div>
              ) : (
                <div className="event-table-wrapper">
                  <div className="event-table">
                    <div className="event-table--header event-col--selectbox">
                      {selectAll}
                    </div>
                    <div className="event-table--header event-col--asin">
                      ASIN
                      {this.sortCarets("product.asin")}
                    </div>
                    <div className="event-table--header event-col--parent-asin">
                      Parent
                      {this.sortCarets("product.parent_asin")}
                    </div>
                    <div className="event-table--header event-col--product">
                      Product
                      {this.sortCarets("product.title")}
                    </div>
                    <div className="event-table--header event-col--change-type">
                      What Changed
                      {this.sortCarets("change_type")}
                    </div>
                    <div className="event-table--header event-col--created-at">
                      Noticed on
                      {this.sortCarets("created_at")}
                    </div>
                    <div className="event-table--header event-col--tags">
                      Tags
                      {this.sortCarets("change_type")}
                    </div>
                    <div className="event-table--header event-col--updated-by">
                      Updated by
                    </div>
                    <div className="event-table--header event-col--category">
                      Category
                      {this.sortCarets("category_type")}
                    </div>
                    <div className="event-table--header event-col--activity-type">
                      Activity type
                      {this.sortCarets("activity_type")}
                    </div>
                    <div className="event-table--header event-col--notes">
                      Notes
                    </div>
                  </div>
                  <div>{items}</div>
                </div>
              )}
            </div>
            <EventPagination
              handlePaginationClick={this.handlePaginationClick}
              previousPage={
                this.state.page > 1 ? this.state.page - 1 : this.state.page
              }
              nextPage={this.state.page + 1}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventsManager.propTypes = {
  brandId: PropTypes.number,
  brandName: PropTypes.string,
  events: PropTypes.array,
  changeTypeFilter: PropTypes.string,
  chartData: PropTypes.shape({
    datasets: PropTypes.array,
    labels: PropTypes.array,
  }),
  chartOptions: PropTypes.object,
  changeType: PropTypes.string,
  changeTypeFilterOptions: PropTypes.array,
  activityTypes: PropTypes.array,
  categories: PropTypes.array,
  categoryFilterOptions: PropTypes.array,
  tagFilterOptions: PropTypes.array,
  selectedCount: PropTypes.number,
  chartView: PropTypes.number,
  isLoading: PropTypes.bool,
  categorizedCount: PropTypes.number,
  uncategorizedCount: PropTypes.number,
  activityCount: PropTypes.number,
  currentUser: PropTypes.object,
  indexationCount: PropTypes.number,
  contentCount: PropTypes.number,
  variationCount: PropTypes.number,
  imageCount: PropTypes.number,
  inputClass: PropTypes.string,
  linkClass: PropTypes.string,
  buttonClass: PropTypes.string,
};

export default EventsManager;