import React, { Component } from "react";
import PropTypes from "prop-types";

import EventsForReviewForm from "./EventsForReviewForm";
import IndividualPendingEventForm from "./IndividualPendingEventForm";

export default class EventForm extends Component {
  static propTypes = {
    activityTypes: PropTypes.array,
    brandId: PropTypes.number,
    categories: PropTypes.array,
    ignoreEvents: PropTypes.func,
    event: PropTypes.object,
    fetchEvents: PropTypes.func,
    isLoading: PropTypes.bool,
    selectedQueue: PropTypes.string,
    setToLoading: PropTypes.func,
    updateEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      notes: null,
      category: null,
      errors: [],
      message: null,
    };
  }

  render() {
    let { activityTypes, categories, event, isForReviewQueue, selectedQueue } =
      this.props;

    return isForReviewQueue ? (
      <EventsForReviewForm
        categories={categories}
        ignoreEvents={(id) => this.props.ignoreEvents(id)}
        event={event}
        fetchEvents={() => this.props.fetchEvents()}
        isBulkUpdate={false}
        isLoading={this.state.isLoading}
        selectedQueue={selectedQueue}
        setToLoading={() => this.props.setToLoading()}
        updateEvents={(eventIds, columns) =>
          this.props.updateEvents(eventIds, columns)
        }
      />
    ) : (
      <IndividualPendingEventForm
        activityTypes={activityTypes}
        event={event}
        ignoreEvents={(id) => this.props.ignoreEvents(id)}
        fetchEvents={() => this.props.fetchEvents()}
        isBulkUpdate={false}
        isLoading={this.state.isLoading}
        setToLoading={() => this.props.setToLoading()}
        updateEvents={(eventIds, columns) =>
          this.props.updateEvents(eventIds, columns)
        }
      />
    );
  }
}
