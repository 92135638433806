import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { Line } from "react-chartjs-2";

class LineChart extends Component {
  render() {
    const { data, height, options } = this.props;

    return (
      <div>
        <Line data={data} height={height} options={options} />
      </div>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  }).isRequired,
  height: PropTypes.number,
  options: PropTypes.object,
};

LineChart.defaultProps = {
  data: {
    datasets: [],
    labels: [],
  },
  height: "auto",
  options: {},
};

export default LineChart;
