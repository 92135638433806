import React, { Component } from "react";
import PropTypes from "prop-types";

import * as differ from "diff";
import { compact, isNil, startCase } from "lodash";

export default class EventStackedRow extends Component {
  static propTypes = {
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDiff: false,
    };
  }

  render() {
    if (item[parent_asin]) {
      return (
        <div>{item[parent_asin]}</div>
      )
    }
  }
}