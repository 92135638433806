import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, isNil } from "lodash";

export default class IndividualPendingEventForm extends Component {
  static propTypes = {
    activityTypes: PropTypes.array,
    closeModal: PropTypes.func,
    deleteEvents: PropTypes.func,
    event: PropTypes.object,
    fetchEvents: PropTypes.func,
    isBulkUpdate: PropTypes.bool,
    isLoading: PropTypes.bool,
    setToLoading: PropTypes.func,
    selectedEvents: PropTypes.array,
    updateEvents: PropTypes.func,
    ignoreEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activityType: this.props.event ? this.props.event.activity_type : null,
      notes: this.props.event ? this.props.event.notes : null,
      ignored: this.props.event ? this.props.event.ignored : false,
      errors: [],
      message: null,
    };
  }

  formIsValid = () => {
    const { activityType, notes } = this.state;
    let activityTypeChanged =
      this.props.event && activityType !== this.props.event.activity_type;
    let notesChanged = this.props.event && notes !== this.props.event.notes;
    let attributesChanged = activityTypeChanged || notesChanged;

    if (isEmpty(activityType) || isNil(activityType) || !attributesChanged)
      return false;

    return true;
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleIgnoreEvents = () => {
    this.setState({ ignored: true });
  };

  handleActivityTypeChange = (activityType) => {
    this.setState({ activityType: activityType });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    if (!this.formIsValid()) return false;

    this.props.setToLoading();

    let { event, isBulkUpdate, selectedEvents } = this.props;
    let { activityType, notes } = this.state;
    let eventIds = isBulkUpdate ? selectedEvents.map((e) => e.id) : [event.id];

    this.props.updateEvents(eventIds, {
      activity_type: activityType,
      notes: notes,
    });
  };

  showErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) return null;

    return (
      <React.Fragment>
        <div className="alert alert-danger">
          {errors.map((error, index) => (
            <div>
              <span className="alert-text" key={index}>
                {error}
              </span>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    let { activityTypes, isBulkUpdate, isLoading } = this.props;
    let { activityType, notes } = this.state;

    let activityTypeField = (
      <div className="btn-group-toggle" data-toggle="buttons">
        {activityTypes.map((o) => {
          let isActive = activityType == o.value;
          return (
            <label
              className={`cursor-pointer py-2 px-4 m-1 text-sm font-medium text-gray-900 rounded-md border border-gray-200 hover:text-white hover:bg-blue-500 ${isActive ? "text-white bg-blue-400" : ""
                }`}
              onClick={() => this.handleActivityTypeChange(o.value)}
            >
              <input
                type="radio"
                className="hidden"
                name={o.value}
                id={o.value}
                checked={activityType || ""}
              />{" "}
              <i className="fa fa-check-circle" /> {o.label}
            </label>
          );
        })}
      </div>
    );

    return (
      <div>
        {this.showErrors()}

        <div className="mb-4">
          <label
            htmlFor="activityType"
            className="block text-gray-600 text-sm font-normal mb-2"
          >
            What type of change did you make?
          </label>
          {activityTypeField}
        </div>

        <div className="mb-4">
          <label className="block text-gray-600 text-sm font-normal mb-1">
            Notes
          </label>
          <input
            type="text"
            className="shadow appearance-none border border-gray-300 rounded w-full px-3 py-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={notes || ""}
            placeholder="Any additional details..."
            onChange={this.handleNotesChange}
          />
        </div>

        {isBulkUpdate ? (
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={isLoading}
            onClick={() => this.props.closeModal()}
          >
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-pulse" /> Loading…
              </span>
            ) : (
              <span>Cancel</span>
            )}
          </button>
        ) : (
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm mr-1"
            disabled={isLoading}
            onClick={() => this.handleIgnoreEvents()}
          >
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-pulse" /> Loading…
              </span>
            ) : (
              <span>
                <i className="fa fa-trash-o" /> Ignore
              </span>
            )}
          </button>
        )}
        <button
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          disabled={isLoading}
          onClick={this.handleSaveClick}
          disabled={!this.formIsValid()}
        >
          {isLoading ? (
            <span>
              <i className="fa fa-spinner fa-pulse" /> Loading…
            </span>
          ) : (
            <span>Update</span>
          )}
        </button>
      </div>
    );
  }
}
