import React, { Component } from "react";
import PropTypes from "prop-types";

class FieldLabel extends Component {
  static propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span
        className="flex cursor-pointer items-center hover:bg-blue-300 bg-blue-100 text-blue-500 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded mb-4"
        onClick={this.props.onClick}
      >
        {this.props.value}{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    );
  }
}

export default FieldLabel;
