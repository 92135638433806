const fetch = window.fetch;

// Ensure that a fetch response is 2XX class, otherwise fail and reject the
// promise: https://github.com/github/fetch#handling-http-error-statuses
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const message = response.statusText || response.status;
    const error = new Error(message);

    error.response = response;
    throw error;
  }
};

// Get current page's CSRF token (necessary when POSTing to the backend)
const getCSRFToken = () => {
  let metaTag = document.querySelector("meta[name='csrf-token']");
  if (metaTag) {
    return metaTag.getAttribute("content");
  }
};

// Parse JSON of a fetch() response in a Promise pipeline
const parseJSON = (response) => response.json();

export const extractErrorMessage = (error, defaultMessage) =>
  error.response
    .json()
    .then((data) => data.message || defaultMessage)
    .catch(() => defaultMessage);

// Base fetch function
const localFetch = (url, options) => {
  const method = options.method || "GET";

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": getCSRFToken(),
  };

  const credentials = "same-origin";

  const body = options.body
    ? {
        body: JSON.stringify(options.body),
      }
    : {};

  return fetch(url, {
    method,
    headers,
    credentials,
    ...body,
  })
    .then(checkStatus)
    .then(parseJSON);
};

export const localGet = (url) => localFetch(url, { method: "GET" });

export const localPostJSON = (url, body) =>
  localFetch(url, { method: "POST", body });

export const localPutJSON = (url, body) =>
  localFetch(url, { method: "PUT", body });

export const localDelete = (url) => localFetch(url, { method: "DELETE" });
