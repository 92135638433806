import React, { Component } from "react";
import { Fragment } from "react";
import PropTypes from "prop-types";

import { isNil } from "lodash";

class ProductImages extends Component {
  constructor(props) {
    super(props);

    let activeImage = isNil(this.props.images)
      ? null
      : this.props.images[0].url;

    this.state = {
      images: this.props.images,
      activeImage: activeImage,
    };
  }

  handleImageClick = (newImage, e) => {
    e.preventDefault();

    this.setState({ activeImage: newImage });
  };

  render() {
    let { activeImage, images } = this.state;

    console.log("images");
    console.log(images);
    let hasImages = !isNil(images);

    let mainImage = (
      <figure>
        {hasImages ? (
          <img
            src={activeImage}
            className="figure-img img-fluid figure-product rounded"
          />
        ) : (
          <div className="rounded figure-placeholder d-flex justify-content-center">
            <i className="fa fa-image align-self-center fa-lg text-muted"></i>
            &nbsp;
          </div>
        )}
      </figure>
    );

    let thumbnails = hasImages ? (
      <Fragment>
        {images.map((img, index) => {
          let isActive = activeImage == img.url;

          return (
            <div
              key={index}
              value={img.url}
              className="cursor-pointer product-thumbnail"
              onClick={(e) => this.handleImageClick(img.url, e)}
            >
              <div
                className={`${isActive ? "product-thumbnail--active" : null}`}
              >
                <img src={img.url} />
              </div>
            </div>
          );
        })}
      </Fragment>
    ) : null;

    return (
      <div className="product-images-container">
        <div className="thumbnails">{thumbnails}</div>
        <div className="main-image">{mainImage}</div>
      </div>
    );
  }
}

ProductImages.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ProductImages;
