import React, { Component } from "react";
import PropTypes from "prop-types";

class FieldLabel extends Component {
  static propTypes = {
    label: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <label className="block text-gray-600 text-sm font-normal mb-1">
        {this.props.label}
      </label>
    );
  }
}

export default FieldLabel;
