import React, { Component } from "react";
import PropTypes from "prop-types";

import FieldLabel from "./FieldLabel";
import { isEmpty, isNil, some } from "lodash";

export default class EventsForReviewForm extends Component {
  static propTypes = {
    categories: PropTypes.array,
    closeModal: PropTypes.func,
    ignoreEvents: PropTypes.func,
    event: PropTypes.object,
    fetchEvents: PropTypes.func,
    isBulkUpdate: PropTypes.bool,
    isLoading: PropTypes.bool,
    selectedEvents: PropTypes.array,
    selectedQueue: PropTypes.string,
    setToLoading: PropTypes.func,
    updateEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      notes: null,
      category: null,
      errors: [],
      message: null,
    };
  }

  formIsValid = () => {
    const { category, notes } = this.state;
    let categoryChanged =
      this.props.event && category !== this.props.event.activity_type;
    let notesChanged = this.props.event && notes !== this.props.event.notes;
    let attributesChanged = categoryChanged || notesChanged;

    if (isEmpty(category) || isNil(category) || !attributesChanged)
      return false;

    return true;
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleActivityTypeChange = (category) => {
    this.setState({ category: category });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    if (!this.formIsValid()) return false;

    this.props.setToLoading();

    let { event, isBulkUpdate, selectedEvents } = this.props;
    // let eventIds = selectedEvents;
    let eventIds = isBulkUpdate ? selectedEvents.map((e) => e.id) : [event.id];
    console.log(eventIds)

    this.props
      .updateEvents(eventIds, {
        notes: this.state.notes,
        category: this.state.category,
      })
      .then((res) => {
        this.setState({
          notes: null,
          category: null,
          message: res.message,
        });
        this.props.fetchEvents();
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then(({ errors }) => {
          this.setState({ errors });
        });
      });
  };

  showMessage = () => {
    const { message } = this.state;
    if (!message) return null;

    return (
      <React.Fragment>
        <div className="alert alert-success">
          <div>
            <span className="alert-text">{message}</span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  showErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) return null;

    return (
      <React.Fragment>
        <div className="alert alert-danger">
          {errors.map((error, index) => (
            <div>
              <span className="alert-text" key={index}>
                {error}
              </span>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  getActivityType = (c) => {
    return this.props.isBulkUpdate
      ? some(this.props.selectedEvents, ["change_type", c])
      : this.props.event.change_type == c;
  };

  render() {
    let { categories, isBulkUpdate, isLoading, selectedQueue } = this.props;
    let { notes, category } = this.state;

    let isContent = this.getActivityType("content");
    let isImage = this.getActivityType("image");
    let isIndexation = this.getActivityType("indexation");
    let isVariation = this.getActivityType("variation");

    let categoryField = (
      <div className="flex flex-wrap" data-toggle="buttons">
        {categories.map((o) => {
          let isHidden =
            (selectedQueue !== "categorized" && o.value == "uncategorized") ||
            (isIndexation &&
              !["uncategorized", "suppression", "unavailable"].includes(
                o.value
              )) ||
            ((isContent || isImage) &&
              !["uncategorized", "optimized", "unauthorized_change"].includes(
                o.value
              )) ||
            (isVariation &&
              ![
                "uncategorized",
                "hijacker",
                "new_variation",
                "broken_variation",
                "added_variant",
                "suppression",
                "in_stock",
                "out_of_stock",
              ].includes(o.value));
          let isActive = category == o.value;

          return (
            <label
              key={o.value}
              className={`cursor-pointer py-2 px-4 m-1 text-sm font-medium text-gray-900 rounded-md border border-gray-200 hover:text-white hover:bg-blue-500 ${isActive ? "text-white bg-blue-400" : ""
                } ${isHidden ? " hidden" : ""}`}
              onClick={() => this.handleActivityTypeChange(o.value)}
            >
              <input
                type="radio"
                name="category"
                className="hidden"
                notes={o.value}
                id={o.value}
              />{" "}
              {o.label}
            </label>
          );
        })}
      </div>
    );

    return (
      <div>
        {this.showErrors()}
        {this.showMessage()}
        <div className="mb-4">
          <FieldLabel label="Why did this change occur?" />
          {categoryField}
        </div>
        <div className="mb-4">
          <FieldLabel label="Notes" />
          <input
            type="text"
            className="shadow appearance-none border border-gray-300 rounded w-full px-3 py-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={notes || ""}
            placeholder="Any additional details..."
            onChange={this.handleNotesChange}
          />
        </div>
        {isBulkUpdate ? (
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={isLoading}
            onClick={() => this.props.closeModal()}
          >
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-pulse" /> Loading…
              </span>
            ) : (
              <span>Cancel</span>
            )}
          </button>
        ) : (
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={isLoading}
            onClick={() => this.props.ignoreEvents()}
          >
            {isLoading ? (
              <span>
                <i className="fa fa-spinner fa-pulse" /> Loading…
              </span>
            ) : (
              <span>
                <i className="fa fa-trash-o" /> Ignore
              </span>
            )}
          </button>
        )}
        <button
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={this.handleSaveClick}
          disabled={!this.formIsValid() || isLoading}
        >
          {isLoading ? (
            <span>
              <i className="fa fa-spinner fa-pulse" /> Loading…
            </span>
          ) : (
            <span>Update</span>
          )}
        </button>
      </div>
    );
  }
}