import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal"; // Import the Modal from react-modal
import { isEmpty, isNil } from "lodash";
import FieldLabel from "./FieldLabel";
import IndividualPendingEventForm from "./IndividualPendingEventForm";
import EventsForReviewForm from "./EventsForReviewForm";

class ManageParentAsinForm extends Component {
  static propTypes = {
    parentAsin: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    activityTypes: PropTypes.array,
    categories: PropTypes.array,
    ignored: PropTypes.bool,
    closeModal: PropTypes.func,
    fetchEvents: PropTypes.func,
    isForReviewQueue: PropTypes.bool,
    selectedCount: PropTypes.number,
    selectedChangeType: PropTypes.string,
    selectedEvents: PropTypes.array,
    selectedQueue: PropTypes.string,
    setToLoading: PropTypes.func,
    showModal: PropTypes.bool,
    updateEvents: PropTypes.func,
    ignoreEvents: PropTypes.func, // Ensure this function is passed as a prop
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      notes: null,
      category: null,
      errors: [],
      message: null,
    };

    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  formIsValid = () => {
    const { category, notes } = this.state;
    let categoryChanged =
      this.props.event && category !== this.props.event.activity_type;
    let notesChanged = this.props.event && notes !== this.props.event.notes;
    let attributesChanged = categoryChanged || notesChanged;

    if (isEmpty(category) || isNil(category) || !attributesChanged)
      return false;

    return true;
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleSaveClick = async (e) => {
    e.preventDefault();
    if (!this.formIsValid()) return false;

    this.props.setToLoading();

    const { notes } = this.state;
    const { category } = this.state;
    const { selectedEvents, parentAsin } = this.props;

    // Log selectedEvents and parentAsin to inspect the values
    console.log("selectedEvents:", selectedEvents);
    console.log("parentAsin:", parentAsin);

    // Update the parent ASIN
    try {
      await this.props.updateEvents([parentAsin], { notes: this.state.notes, category: this.state.category });

      // Filter out child ASINs (all except the parent ASIN)
      const childAsins = selectedEvents.filter(event => event !== parentAsin);

      // Run the ignore request on child ASINs
      if (childAsins.length > 0) {
        await this.props.ignoreEvents(childAsins);
      }

      this.setState({
        notes: null,
        category: null,
        message: "Update successful",
      });

      // Close the modal after update
      this.props.onClose();
    } catch (err) {
      console.error(err);
      err.response.json().then(({ errors }) => {
        this.setState({ errors });
      });
    } finally {
      this.setState({ loading: false });
      this.props.fetchEvents(); // Refresh the events after update
    }
  };

  showErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) return null;

    return (
      <div className="alert alert-danger">
        {errors.map((error, index) => (
          <div key={index}>
            <span className="alert-text">{error}</span>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      activityTypes,
      categories,
      isForReviewQueue,
      selectedEvents,
      showModal,
      selectedQueue,
    } = this.props;
    const { loading } = this.state;

    // Log props to inspect the values passed to this component
    console.log("ManageParentAsinForm props:", this.props);

    return (
      <Modal
        isOpen={showModal}
        onRequestClose={this.props.onClose}
        contentLabel="Manage Parent ASIN Modal"
        className="your-modal-class"
        overlayClassName="your-overlay-class"
      >
        <div className="modal-content">
          {this.showErrors()}
          <div className="mt-2">
            {isForReviewQueue ? (
              <EventsForReviewForm
                categories={categories}
                closeModal={this.props.onClose}
                ignoreEvents={this.props.ignoreEvents}
                event={selectedEvents[0]}
                fetchEvents={this.props.fetchEvents}
                isBulkUpdate={true}
                isLoading={loading}
                selectedEvents={this.props.parentAsin.events}
                selectedQueue={selectedQueue}
                setToLoading={this.props.setToLoading}
                updateEvents={this.props.updateEvents}
              />
            ) : (
              <IndividualPendingEventForm
                activityTypes={activityTypes}
                closeModal={this.props.onClose}
                ignoreEvents={this.props.ignoreEvents}
                event={selectedEvents[0]}
                fetchEvents={this.props.fetchEvents}
                isBulkUpdate={true}
                isLoading={loading}
                selectedEvents={selectedEvents}
                setToLoading={this.props.setToLoading}
                updateEvents={this.props.updateEvents}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ManageParentAsinForm;