import React, { Component } from "react";
import PropTypes from "prop-types";

class EventFilterCard extends Component {
  static propTypes = {
    color: PropTypes.string,
    count: PropTypes.number,
    heading: PropTypes.string,
    icon: PropTypes.string,
    applyFilters: PropTypes.func,
    active: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  renderIcon = (heading) => {
    var heading;
    switch (heading) {
      case "Content":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-file-text text-blue-600"
          >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        );
      case "Variation":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-git-pull-request text-indigo-600"
          >
            <circle cx="18" cy="18" r="3"></circle>
            <circle cx="6" cy="6" r="3"></circle>
            <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
            <line x1="6" y1="9" x2="6" y2="21"></line>
          </svg>
        );
      case "Indexation":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search text-green-600"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        );
      case "Image":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-image text-yellow-600"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <circle cx="8.5" cy="8.5" r="1.5"></circle>
            <polyline points="21 15 16 10 5 21"></polyline>
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-image text-green-600"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <circle cx="8.5" cy="8.5" r="1.5"></circle>
            <polyline points="21 15 16 10 5 21"></polyline>
          </svg>
        );
    }
  };

  render() {
    let { applyFilters, count, heading, active } = this.props;

    return (
      <a
        className={
          "p-5 flex items-center justify-between cursor-pointer bg-white rounded-md shadow-md border" +
          (active && heading == "Indexation"
            ? " border-green-600 "
            : active && heading == "Image"
            ? " border-yellow-600 "
            : active && heading == "Variation"
            ? " border-indigo-600 "
            : active && heading == "Content"
            ? " border-blue-600 "
            : "") +
          (heading == "Indexation"
            ? " hover:border-green-600 "
            : heading == "Image"
            ? " hover:border-yellow-600 "
            : heading == "Variation"
            ? " hover:border-indigo-600 "
            : heading == "Content"
            ? " hover:border-blue-600 "
            : "")
        }
        value={heading}
        onClick={() => applyFilters(heading.toLowerCase())}
      >
        <div>
          <div className="text-xs text-gray-400 uppercase">{heading}</div>
          <div className="flex items-center pt-1">
            <div className="text-xl font-bold text-gray-900 ">
              {count} Changes
            </div>
          </div>
        </div>
        <div className={"text-blue-300 " + (active ? " text-blue-500" : "")}>
          {this.renderIcon(heading)}
        </div>
      </a>
    );
  }
}

export default EventFilterCard;
