import * as React from "react";
import { createRoot } from "react-dom/client"; // Use createRoot
import ProductImages from "./components/ProductImages";
import EventsManager from "./components/EventsManager";
import EventsManager2 from "./components/EventsManager2";
import BarChart from "./components/BarChart";
import DonutChart from "./components/DonutChart";
import LineChart from "./components/LineChart";
import jQuery from "jquery";
import debounce from "lodash/debounce";
import html2canvas from 'html2canvas';
import download from 'downloadjs';

clickListener = () =>
  document.addEventListener("click", function handleClick(event) {
    const mobileMenu = document.getElementById("mobile-menu");

    if (event.target.id == "hamburger") {
      mobileMenu.classList.toggle("hidden");
    }
  });

asinFieldInputListener = () => {
  var asins = jQuery('#asins');
  var submitButton = jQuery('#validatedSubmit');

  asins.on('input', function (event) {
    var check = debounce(() => {
      jQuery.each(jQuery('#asins').val().split(/\n/), function (i, line) {
        let correctLength = line.length == 10;
        let noSpecialCharacters = !(/[^A-Za-z0-9]/).test(line);
        let isValid = correctLength && noSpecialCharacters;

        if (correctLength) {
          jQuery("#correctLength").removeClass("text-red-500").addClass("text-green-500");
          jQuery("#correctLength").children(".fe").removeClass("hidden").removeClass("fe-close").addClass("fe-check");
        } else {
          jQuery("#correctLength").removeClass("text-green-500").addClass("text-red-500");
          jQuery("#correctLength").children(".fe").removeClass("hidden").removeClass("fe-check").addClass("fe-close");
        }

        if (noSpecialCharacters) {
          jQuery("#noSpecialCharacters").removeClass("text-red-500").addClass("text-green-500");
          jQuery("#noSpecialCharacters").children(".fe").removeClass("hidden").removeClass("fe-close").addClass("fe-check");
        } else {
          jQuery("#noSpecialCharacters").removeClass("text-green-500").addClass("text-red-500");
          jQuery("#noSpecialCharacters").children(".fe").removeClass("hidden").removeClass("fe-check").addClass("fe-close");
        }

        submitButton.attr("disabled", !isValid)
      });
    }, 400);

    check();
  });
}

function handleDownloadImage(chartType) {
  const chartElement = document.getElementById(chartType);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10); 
  
  html2canvas(chartElement).then(canvas => {
    const dataURL = canvas.toDataURL('image/png');
    download(dataURL, `${chartType}-${formattedDate}.png`, 'image/png');
  });
}

document.addEventListener("DOMContentLoaded", () => {
  clickListener();

  // ASINs Character Count
  const textArea = document.getElementById("asins");
  if (textArea) {
    asinFieldInputListener();
  }

  // Product Images Element
  const ProductImagesElement = document.getElementById("ProductImages");
  if (ProductImagesElement) {
    const node = document.getElementById("data");
    const data = JSON.parse(node.getAttribute("data"));
    const root = createRoot(ProductImagesElement);
    root.render(<ProductImages images={data} />);
  }

  // Events Manager Element
  const EventsManagerElement = document.getElementById("EventsManager");
  if (EventsManagerElement) {
    const node = document.getElementById("data");
    const data = JSON.parse(node.getAttribute("data"));
    const root = createRoot(EventsManagerElement);
    root.render(
      <EventsManager
        brandId={data.brandId}
        brandName={data.brandName}
        events={data.events}
        changeTypeFilter={data.changeTypeFilter}
        chartData={data.chartData}
        chartOptions={data.chartOptions}
        selectedChangeType={data.selectedChangeType}
        changeTypeFilterOptions={data.changeTypeFilterOptions}
        activityTypes={data.activityTypes}
        categories={data.categories}
        categoryFilterOptions={data.categoryFilterOptions}
        tagFilterOptions={data.tagFilterOptions}
        selectedCount={data.selectedCount}
        chartView={data.chartView}
        isLoading={data.isLoading}
        categorizedCount={data.categorizedCount}
        uncategorizedCount={data.uncategorizedCount}
        activityCount={data.activityCount}
        currentUser={data.currentUser}
        indexationCount={data.indexationCount}
        contentCount={data.contentCount}
        variationCount={data.variationCount}
        imageCount={data.imageCount}
        inputClass={data.inputClass}
        selectClass={data.selectClass}
        buttonClass={data.buttonClass}
        labelClass={data.labelClass}
      />
    );
  }

  // Events Manager 2 Element
  const EventsManager2Element = document.getElementById("EventsManager2");
  if (EventsManager2Element) {
    const node = document.getElementById("data");
    const data = JSON.parse(node.getAttribute("data"));
    const root = createRoot(EventsManager2Element);
    root.render(
      <EventsManager2
        brandId={data.brandId}
        brandName={data.brandName}
        events={data.events}
        clumpedEvents={data.clumpedEvents}
        changeTypeFilter={data.changeTypeFilter}
        chartData={data.chartData}
        chartOptions={data.chartOptions}
        selectedChangeType={data.selectedChangeType}
        changeTypeFilterOptions={data.changeTypeFilterOptions}
        activityTypes={data.activityTypes}
        categories={data.categories}
        categoryFilterOptions={data.categoryFilterOptions}
        tagFilterOptions={data.tagFilterOptions}
        selectedCount={data.selectedCount}
        chartView={data.chartView}
        isLoading={data.isLoading}
        categorizedCount={data.categorizedCount}
        uncategorizedCount={data.uncategorizedCount}
        activityCount={data.activityCount}
        currentUser={data.currentUser}
        indexationCount={data.indexationCount}
        contentCount={data.contentCount}
        variationCount={data.variationCount}
        imageCount={data.imageCount}
        inputClass={data.inputClass}
        selectClass={data.selectClass}
        buttonClass={data.buttonClass}
        labelClass={data.labelClass}
      />
    );
  }

  const ContentScoreDonutChart = document.getElementById(
    "ContentScoreDonutChart"
  );

  if (ContentScoreDonutChart) {
    const ContentScoreDonutChartNode = document.getElementById(
      "ContentScoreDonutChartData"
    );
    const ContentScoreDonutChartData = JSON.parse(
      ContentScoreDonutChartNode.getAttribute("data")
    );
    const root = createRoot(ContentScoreDonutChart);
    root.render(
      <DonutChart
        data={ContentScoreDonutChartData.data}
        height={ContentScoreDonutChartData.height}
        options={ContentScoreDonutChartData.options}
      />
    );
  }

  const ScoreRatingLineChart = document.getElementById("ScoreRatingLineChart");

  if (ScoreRatingLineChart) {
    const ScoreRatingLineChartNode = document.getElementById(
      "ScoreRatingLineChartData"
    );
    const ScoreRatingLineChartData = JSON.parse(
      ScoreRatingLineChartNode.getAttribute("data")
    );
    const root = createRoot(ScoreRatingLineChart);
    root.render(
      <LineChart
        data={ScoreRatingLineChartData.data}
        height={ScoreRatingLineChartData.height}
        options={ScoreRatingLineChartData.options}
      />
    );
  }

  const TitleLengthBarChart = document.getElementById("TitleLengthBarChart");

  if (TitleLengthBarChart) {
    const TitleLengthBarChartNode = document.getElementById(
      "TitleLengthBarChartData"
    );
    const TitleLengthBarChartData = JSON.parse(
      TitleLengthBarChartNode.getAttribute("data")
    );
    const root = createRoot(TitleLengthBarChart);
    root.render(
      <BarChart
        data={TitleLengthBarChartData.data}
        height={TitleLengthBarChartData.height}
        options={TitleLengthBarChartData.options}
      />
    );
  }

  const DescriptionLengthBarChart = document.getElementById(
    "DescriptionLengthBarChart"
  );

  if (DescriptionLengthBarChart) {
    const DescriptionLengthBarChartNode = document.getElementById(
      "DescriptionLengthBarChartData"
    );
    const DescriptionLengthBarChartData = JSON.parse(
      DescriptionLengthBarChartNode.getAttribute("data")
    );
    const root = createRoot(DescriptionLengthBarChart);
    root.render(
      <BarChart
        data={DescriptionLengthBarChartData.data}
        height={DescriptionLengthBarChartData.height}
        options={DescriptionLengthBarChartData.options}
      />
    );
  }

  const ImageCountBarChart = document.getElementById("ImageCountBarChart");

  if (ImageCountBarChart) {
    const ImageCountBarChartNode = document.getElementById(
      "ImageCountBarChartData"
    );
    const ImageCountBarChartData = JSON.parse(
      ImageCountBarChartNode.getAttribute("data")
    );
    const root = createRoot(ImageCountBarChart);
    root.render(
      <BarChart
        data={ImageCountBarChartData.data}
        height={ImageCountBarChartData.height}
        options={ImageCountBarChartData.options}
      />
    );
  }

  const NumberOfBulletsBarChart = document.getElementById(
    "NumberOfBulletsBarChart"
  );
  if (NumberOfBulletsBarChart) {
    const NumberOfBulletsBarChartNode = document.getElementById(
      "NumberOfBulletsBarChartData"
    );
    const NumberOfBulletsBarChartData = JSON.parse(
      NumberOfBulletsBarChartNode.getAttribute("data")
    );
    const root = createRoot(NumberOfBulletsBarChart);
    root.render(
      <BarChart
        data={NumberOfBulletsBarChartData.data}
        height={NumberOfBulletsBarChartData.height}
        options={NumberOfBulletsBarChartData.options}
      />
    );
  }

  const BulletCharacterCountBarChart = document.getElementById(
    "BulletCharacterCountBarChart"
  );

  if (BulletCharacterCountBarChart) {
    const BulletCharacterCountBarChartNode = document.getElementById(
      "BulletCharacterCountBarChartData"
    );
    const BulletCharacterCountBarChartData = JSON.parse(
      BulletCharacterCountBarChartNode.getAttribute("data")
    );
    const root = createRoot(BulletCharacterCountBarChart);
    root.render(
      <BarChart
        data={BulletCharacterCountBarChartData.data}
        height={BulletCharacterCountBarChartData.height}
        options={BulletCharacterCountBarChartData.options}
      />
    );
  }

  // brands/:id/charts
  const EventTypeChart = document.getElementById(
    "EventTypeChart"
  );

  if (EventTypeChart) {
    const EventTypeChartNode = document.getElementById(
      "EventTypeChartProps"
    );
    const EventTypeChartProps = JSON.parse(
      EventTypeChartNode.getAttribute("data")
    );
    const root = createRoot(EventTypeChart);
    root.render(
      <BarChart
        data={EventTypeChartProps.data}
        height={EventTypeChartProps.height}
        options={EventTypeChartProps.options}
      />
    );
  }

  // brands/:id/charts
  const IndexationReport = document.getElementById(
    "IndexationReport"
  );

  if (IndexationReport) {
    const IndexationReportNode = document.getElementById(
      "IndexationReportProps"
    );
    const IndexationReportProps = JSON.parse(
      IndexationReportNode.getAttribute("data")
    );
    const root = createRoot(IndexationReport);
    root.render(
      <LineChart
        data={IndexationReportProps.data}
        height={IndexationReportProps.height}
        options={IndexationReportProps.options}
        showPercentage={IndexationReportProps.showPercentage}
      />
    );
  }

  // export chart png
  const exportGraphButton = document.getElementById('exportGraph');

  if (exportGraphButton) {
    exportGraphButton.addEventListener('click', function() {
      if (IndexationReport) {
        handleDownloadImage('IndexationReport');
      } else {
        handleDownloadImage('EventTypeChart');
      }
    });
  }

  const exportToggleButton = document.getElementById('exportOption')
  const exportOptions = document.getElementById('exportOptions');

  if (exportToggleButton) {
    exportToggleButton.addEventListener('click', (e) => {
      exportOptions.classList.toggle('hidden');
    })
    document.addEventListener('click', (e) => {
      if (!exportToggleButton.contains(e.target)) {
        exportOptions.classList.add('hidden');
      }
    });
    const exportGraphButton = document.getElementById('exportGraph');

    if (exportGraphButton) {
      exportGraphButton.addEventListener('click', function() {
        if (IndexationReport) {
          handleDownloadImage('IndexationReport');
        } else if (EventTypeChart) {
          handleDownloadImage('EventTypeChart');
        } else {
          console.log('No data to export');
        }
      });
    }
  }

  // brands/:id/reports
  const ActivityLineChart = document.getElementById(
    "ActivityLineChart"
  );

  if (ActivityLineChart) {
    const ActivityLineChartNode = document.getElementById(
      "ActivityLineChartData"
    );
    const ActivityLineChartData = JSON.parse(
      ActivityLineChartNode.getAttribute("data")
    );
    const root = createRoot(ActivityLineChart);
    root.render(
      <LineChart
        data={ActivityLineChartData.data}
        height={ActivityLineChartData.height}
        options={ActivityLineChartData.options}
      />
    );
  }
});
