import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { Bar } from "react-chartjs-2";

class BarChart extends Component {
  render() {
    const { data, height, options } = this.props;

    if (options.plugins.datalabels) {
      options.plugins.datalabels.formatter = (value, context) => {
        return `${context.dataset.label}: ${value}`;
      }
    }

    return (
      <div>
        <Bar data={data} height={height} options={options} />
      </div>
    );
  }
}

BarChart.propTypes = {
  data: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  }).isRequired,
  height: PropTypes.number,
  options: PropTypes.object.isRequired,
};

BarChart.defaultProps = {
  data: {
    datasets: [],
    labels: [],
  },
  height: "auto",
  options: {},
};

export default BarChart;
