import React, { Component } from "react";
import PropTypes from "prop-types";

class EventCategoryButtonGroup extends Component {
  static propTypes = {
    brandId: PropTypes.number,
    uncategorizedCount: PropTypes.number,
    categorizedCount: PropTypes.number,
    activityCount: PropTypes.number,
    isForReviewQueue: PropTypes.bool,
    isCategorizedQueue: PropTypes.bool,
    isActivityQueue: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  render() {
    let {
      brandId,
      uncategorizedCount,
      categorizedCount,
      activityCount,
      isForReviewQueue,
      isCategorizedQueue,
      isActivityQueue,
    } = this.props;

    return (
      <div className="inline-flex rounded-md shadow-sm mb-4" role="group">
        <a
          href={`/brands/${brandId}?queue=to_review`}
          className={
            "py-2 px-4 text-sm font-medium rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700" +
            (isForReviewQueue ? " bg-blue-600 text-white" : " bg-white")
          }
        >
          For Review
          <span className="items-center bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-xl ml-1">
            {uncategorizedCount}
          </span>
        </a>
        <a
          href={`/brands/${this.props.brandId}?queue=categorized`}
          className={
            "py-2 px-4 text-sm font-medium border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700" +
            (isCategorizedQueue ? " bg-blue-600 text-white" : " bg-white")
          }
        >
          Pending
          <span className="items-center bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-xl ml-1">
            {categorizedCount}
          </span>
        </a>
        <a
          href={`/brands/${this.props.brandId}?queue=activities`}
          className={
            "py-2 px-4 text-sm font-medium rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700" +
            (isActivityQueue ? " bg-blue-600 text-white" : " bg-white")
          }
        >
          Activities
          <span className="items-center bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-xl ml-1">
            {activityCount}
          </span>
        </a>
      </div>
    );
  }
}

export default EventCategoryButtonGroup;
