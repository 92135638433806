import React, { useState } from "react";
import PropTypes from "prop-types";

const EventNotes = ({ item }) => {
  const [notes, setNotes] = useState(item.notes || "");

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleUpdateNotes = () => {
    // Logic to save notes (e.g., API call)
    console.log("Notes saved:", notes);
  };

  const handleIgnoreNotes = () => {
    // Logic to save notes (e.g., API call)
    console.log("Notes igmored:", notes);
  };



  return (
    <div className="event-notes">
    <p>Why did this change occur?</p>
      <textarea
        className="w-full p-2 border rounded"
        value={notes}
        onChange={handleNotesChange}
        placeholder="Any additional details..."
      />
      {/* for each  */}
      <button
        className="mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleIgnoreNotes}
      >
        Ignore
      </button>
      <button
        className="mt-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleUpdateNotes}
      >
         Update
      </button>
    </div>
  );
};

EventNotes.propTypes = {
  item: PropTypes.object.isRequired,
};

export default EventNotes;