import React, { Component } from "react";
import PropTypes from "prop-types";

import { isEmpty, isNil } from "lodash";
import IndividualPendingEventForm from "./IndividualPendingEventForm";
import EventsForReviewForm from "./EventsForReviewForm";

export default class EventBulkActionForm extends Component {
  static propTypes = {
    activityTypes: PropTypes.array,
    categories: PropTypes.array,
    ignored: PropTypes.bool,
    closeModal: PropTypes.func,
    fetchEvents: PropTypes.func,
    isForReviewQueue: PropTypes.bool,
    selectedCount: PropTypes.number,
    selectedChangeType: PropTypes.string,
    selectedEvents: PropTypes.array,
    selectedQueue: PropTypes.string,
    setToLoading: PropTypes.func,
    showModal: PropTypes.bool,
    updateEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      notes: null,
      category: null,
      errors: [],
      message: null,
    };

    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  formIsValid = () => {
    const { category } = this.state;

    if (isEmpty(category) || isNil(category)) return false;

    return true;
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleCategoryChange = (category) => {
    this.setState({ category: category });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    if (!this.formIsValid()) return false;

    this.props.setToLoading();

    let { selectedEvents } = this.props;
    let { notes, category } = this.state;

    this.props
      .updateEvents(selectedEvents, {
        notes: notes,
        category: category,
      })
      .then((res) => {
        this.setstate({
          notes: null,
          category: null,
          message: res.message,
        });
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then(({ errors }) => {
          this.setState({ errors });
        });
      });
  };

  showErrors = () => {
    const { errors } = this.state;
    if (errors.length === 0) return null;

    return (
      <React.Fragment>
        <div className="alert alert-danger">
          {errors.map((error, index) => (
            <div>
              <span className="alert-text" key={index}>
                {error}
              </span>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    let {
      activityTypes,
      categories,
      isForReviewQueue,
      selectedEvents,
      showModal,
      selectedQueue,
    } = this.props;
    let { isLoading } = this.state;

    return (
      <div
        style={{ display: showModal ? "block" : "none" }}
        id="activityCreateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activityCreateModalLabel"
        className={showModal ? "modal fade show" : "modal"}
      >
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Update Change
                      </h3>
                      <div className="mt-2">
                        {isForReviewQueue ? (
                          <div className="modal-body">
                            <EventsForReviewForm
                              categories={categories}
                              closeModal={() => this.props.closeModal()}
                              ignoreEvents={() =>
                                this.props.ignoreEvents([item.id])
                              }
                              event={event}
                              fetchEvents={() => this.fetchEvents()}
                              isBulkUpdate={true}
                              isLoading={this.state.isLoading}
                              selectedEvents={selectedEvents}
                              selectedQueue={selectedQueue}
                              setToLoading={() => this.props.setToLoading()}
                              updateEvents={(eventIds, columns) =>
                                this.props.updateEvents(eventIds, columns)
                              }
                            />
                          </div>
                        ) : (
                          <div className="modal-body">
                            <IndividualPendingEventForm
                              activityTypes={activityTypes}
                              closeModal={() => this.props.closeModal()}
                              ignoreEvents={() =>
                                this.props.ignoreEvents([item.id])
                              }
                              event={event}
                              fetchEvents={() => this.props.fetchEvents()}
                              isBulkUpdate={true}
                              isLoading={isLoading}
                              selectedEvents={selectedEvents}
                              setToLoading={() => this.props.setToLoading()}
                              updateEvents={(eventIds, columns) =>
                                this.props.updateEvents(eventIds, columns)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
